import React from 'react';
import './view.less';

/**
 * @class CnSmartGuide
 * @description 
 */
export default class CnSmartGuide extends React.Component {
  static displayName = 'CnSmartGuide';

  constructor(props) {
    super(props);
  }

  render() {
    return this.props.children;
  }
}
