import React from 'react';
import './view.less';

/**
 * @class CnSmartFtp
 * @description 
 */
export default class CnSmartFtp extends React.Component {
  static displayName = 'CnSmartFtp';

  constructor(props) {
    super(props);
  }

  render() {
    return this.props.children;
  }
}
