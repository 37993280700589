import React from 'react';
import './view.less';

/**
 * @class CnSmartFtpLite
 * @description 
 */
export default class CnSmartFtpLite extends React.Component {
  static displayName = 'CnSmartFtpLite';

  constructor(props) {
    super(props);
  }

  render() {
    return this.props.children;
  }
}
