'use strict';

import CnSmartFtp from './cn-smart-ftp';
import CnSmartFtpLite from './cn-smart-ftp-lite';
import CnSmartForm from './cn-smart-form';
import CnSmartDetail from './cn-smart-detail';
import CnSmartPrimarySub from './cn-smart-primarySub';

import CnSmartFtpGuide from './cn-smart-ftp-guide';
import CnSmartFormGuide from './cn-smart-form-guide';
import CnSmartDetailGuide from './cn-smart-detail-guide';

// 通过arr控制输出的顺序
var bundle = [
  // CnSmartFtp,
  // CnSmartFtpLite,
  // CnSmartForm,
  // CnSmartDetail,
  // CnSmartPrimarySub,

  CnSmartFtpGuide,
  CnSmartFormGuide,
  CnSmartDetailGuide
];

export default bundle;
export {
  // CnSmartFtp,
  // CnSmartFtpLite,
  // CnSmartForm,
  // CnSmartDetail,
  // CnSmartPrimarySub,
  
  CnSmartFtpGuide,
  CnSmartFormGuide,
  CnSmartDetailGuide
};
